import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import logoImage from "assets/images/SaraSewa.png";
import axios from "axios";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { host } from "utils/constraints";

function Basic() {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const navigate = useNavigate();

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  useEffect(() => {
    const storedToken = localStorage.getItem("token");

    if (storedToken) {
      // Token found, perform automatic login
      navigate("/dashboard");
    }
  }, []);

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    setPhoneNumber(value);
  };
  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);
  };

  const handleSignIn = () => {
    let hasErrors = false;
    if (!phoneNumber.trim()) {
      setPhoneNumberError("Phone number is required");
      hasErrors = true;
    } else if (!/^(98|97)\d{8}$/.test(phoneNumber)) {
      setPhoneNumberError(
        "Invalid phone number. It should start with 98 or 97 and have 10 digits."
      );
      hasErrors = true;
    } else {
      setPhoneNumberError("");
    }
    if (!password) {
      setPasswordError("Password is required");
      hasErrors = true;
    } else {
      setPasswordError("");
    }
    if (hasErrors) {
      return;
    }

    axios
      .post(`${host}/user/login`, {
        phoneNumber: phoneNumber,
        password: password,
      })
      .then((response) => {
        if (response.status === 200) {
          const userRole = response.data.objs.user.role;

          if (userRole === "branchadmin" || userRole === "superadmin") {
            toast.success(response.data.msg);
            localStorage.setItem("token", response.data.objs.token);
            localStorage.setItem("refreshToken", response.data.objs.refreshToken);
            localStorage.setItem("username", response.data.objs.user.fullName);
            localStorage.setItem("role", userRole);
            if (userRole === "branchadmin") {
              localStorage.setItem("branchCode", response.data.objs.user.branchCode);
            }
            window.location.reload();
            setTimeout(() => {
              navigate("/dashboard");
            }, 1000);
          } else {
            toast.info("Only admin and superadmin can login.");
          }
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          toast.error(error.response.data.msg);
        }
      });
  };

  return (
    <BasicLayout image={bgImage}>
      <ToastContainer />
      <Card>
        <img
          src={logoImage}
          alt="Logo"
          style={{
            width: "50%",
            display: "block",
            margin: "50px auto 0",
          }}
        />

        <MDBox pt={6} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="PhoneNumber"
                name="phoneNumber"
                fullWidth
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
                error={!!phoneNumberError}
              />
              {phoneNumberError && (
                <MDTypography variant="caption" color="error">
                  {phoneNumberError}
                </MDTypography>
              )}
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Password"
                name="password"
                fullWidth
                value={password}
                onChange={handlePasswordChange}
                error={!!passwordError}
              />
              {passwordError && (
                <MDTypography variant="caption" color="error">
                  {passwordError}
                </MDTypography>
              )}
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Remember me
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth onClick={handleSignIn}>
                sign in
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Don&apos;t have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/authentication/sign-up"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign up
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
